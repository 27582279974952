import React, { useState, useEffect } from 'react';
import './App.css';

function App() {

  const reactApp = document.getElementById('portalGator');
  const subportal = reactApp ? reactApp.getAttribute('subportal') || '' : 8727;
  //const baseUrl = reactApp ? reactApp.getAttribute('base_url') || '': '' ;

  // const [hasError, setErrors] = useState(false);
  const [stories, setStories] = useState({});
  const baseURL = 'https://northernontario.travel';

  useEffect(() => {
    async function fetchData() {
      const res = await fetch("https://northernontario.travel/api/articles-api?_format=json&subportal_tid=" + subportal);
      res
          .json()
          .then(res => setStories(res))
      // .catch(err => setErrors(err));
    }
    fetchData();
  },[subportal]);

  return (
      <div className="tnb-portal-gator-scrolling-wrapper-flexbox">
        {stories.length > 0 ? (
            stories.map((story,i) => {
              return (
                  <a target="_blank" rel="noopener noreferrer" className="tnb-portal-gator-card" key={i} href={story.path}>
                    <div className="tnb-portal-gator-card-wrapper">
                      <img alt="teaser" className="tnb-gator-teaser-image" src={baseURL + story.lead_image_large} />
                      <div className="tnb-portal-gator-title" dangerouslySetInnerHTML={{__html:story.headline}} />
                      { story.sub_hookline === '' ? (
                          <div className="tnb-portal-gator-sub-hookline" dangerouslySetInnerHTML={{__html:story.kicker}} />
                      ) : (
                          <div className="tnb-portal-gator-sub-hookline" dangerouslySetInnerHTML={{__html:story.sub_hookline}} />
                      )}
                      <div className="tnb-portal-gator-author">By {story.author}</div>
                    </div>
                  </a>
              )
            })) : (
            <div>
              Loading ....
            </div>
        )}
      </div>
  );
}

export default App;
